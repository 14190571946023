import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { OptionsService } from 'src/app/services/options/options.service';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { ServiceDatesModel } from 'src/app/services/options/service-dates.model';
import { TranslateService } from '@ngx-translate/core';
import { BookingService, TimeSlotReservation } from 'src/app/services/booking/booking.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config/config.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { take } from 'rxjs/operators';
import { ServiceAdvisorService } from 'src/app/services/service-advisor/service-advisor.service';
import { OptionsModel } from 'src/app/services/options/option.model';

@Component({
    selector: 'app-options-component',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.less']
})
export class OptionsComponent implements OnInit {
    serviceDates: ServiceDatesModel;
    minDate: Date;
    maxDate: Date;
    model: NgbDateStruct;
    startDate: NgbDateStruct;
    showDatePicker: boolean = false;
    timeOptions: TimeSlot[] = [];
    selectedSlot: string;
    timeText: string;
    switchAdvisorWorkflow: boolean;
    vehicleDropOffMassage: boolean;
    pickUpAndDelivery: boolean;
    pickUpAddress: string;
    deliveryAddress: string;
    bookingDate: Date;
    mobilityOptions: OptionsModel[] = [];
    mobilityRequired: boolean;
    isMini = false;
    isBMW = false;
    isSouthAfrica = false;
    inValidCharacters = false
    invalidCharactersInPickUpAddress = false;
    pickUpAddressTextAreaIsInvalid = false;
    deliveryAddressTextAreaIsInvalid = false;
    isLanguageThai = false;
    isLanguageArabic = false;
    selectedPickUpTimeSlot="";
    isRtl = false
    validationRequiredKeyProp = "validation-required"
    timeRequiredTranslationKeyProp = "validation-required"

    @Output() servicesSelected = new EventEmitter<string>();
    @Output() previousState = new EventEmitter<string>();

    constructor(
        private readonly calendar: NgbCalendar,
        private readonly optionService: OptionsService,
        private readonly userService: UserService,
        private readonly translateService: TranslateService,
        private readonly bookingService: BookingService,
        private readonly router: Router,
        private readonly configService: ConfigService,
        private readonly errorService: ErrorService,
        private readonly serviceAdvisorService: ServiceAdvisorService
    ) {

    }

    ngOnInit() {

// console.log("ISRTL", this.isRtl);

      this.isSouthAfrica = this.configService.configSettings.isCRM;
        this.isBMW = this.configService.brand === "BMW";
        this.isMini = this.configService.brand === "MINI";
        this.switchAdvisorWorkflow = this.configService.configSettings.switchAdvisorWorkflow;
        this.vehicleDropOffMassage = this.configService.configSettings.vehicleDropOffMassage;

        if (this.userService.selectedServiceSlot) {
            this.selectedSlot = this.userService.selectedServiceSlot;
        }

        if (this.configService.brand === "BMW") {
            document.documentElement.style.setProperty('--selected-date-color', "#007bff");
        }

        if (this.configService.brand === "MINI") {
            document.documentElement.style.setProperty('--selected-date-color', "orange");
        }



        this.startDate = this.calendar.getToday();

        this.translateService.get('time').subscribe(result => this.timeText = result);
        this.translateService.onLangChange.subscribe((result) => {
          this.isRtl = result.lang.includes('ar_');
          // console.log("lang", result);
          this.isLanguageThai = result.lang === "th";
          this.isLanguageArabic = result.lang.includes('ar_');
            this.translateService.get('time').subscribe(result => this.timeText = result);
            this.showDatePicker = false;
            setTimeout(() => this.showDatePicker = true, 1000);
        })

        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            const endDate = new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day);
            endDate.setDate(endDate.getDate() + 31);
            this.optionService.GetAppointmentDatesTitan(
                new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day),
                endDate
            ).pipe(take(1)).subscribe(dates => {
                this.setUpDates(dates);
            });
        } else {
            this.userService.SelectedServices$.subscribe(result => {
                
                // Removed during SOLINT-2626
                // if (result &&
                //     !result.find(x => x.productCode.includes('SOLRECA') &&
                //         !this.configService.configSettings.fullRecallEnabled))
                    this.optionService.GetAppointmentDates().pipe(take(1)).subscribe(dates => {
                        this.setUpDates(dates);

                      if (this.userService.selectedServiceMobilityOption !== "STAN") {
                        this.deliveryAddress =  this.userService.selectedServiceMobilityDeliveryOptionAddress;
                        this.pickUpAddress  = this.userService.selectedServiceMobilityOptionAddress;
                      }
                    });
            });
        }


        if (this.configService.configSettings.switchAdvisorWorkflow) {
            this.userService.ServiceAdvisor$.subscribe(result => {
                if (result)
                    this.optionService.GetAppointmentDates().pipe(take(1)).subscribe(dates => {
                        this.setUpDates(dates);
                    });
            });
        }


    }

    private setUpDates(dates: ServiceDatesModel) {
        if (dates.workingDates.length === 0) {
            this.errorService.show(991);
            return;
        }
        this.showDatePicker = true;
        this.serviceDates = dates;
        this.userService.serviceDates = dates;

        const activeDate = this.bookingDate || dates.workingDates[0];
        this.bookingDate = new Date(activeDate);
        this.setDefaultDate(activeDate);
        // this.userService.selectedServiceDate = this.bookingDate; // was causing intermettent booking date inconsitencies
        this.getAvailableDropOffTimes(new Date(activeDate), undefined, true);
    }

    submitBooking() {
        this.userService.selectedServiceDate = new Date(this.model.year, this.model.month - 1, this.model.day);
        this.userService.selectedServiceSlot = this.selectedSlot;
        this.userService.selectedServiceMobilityOptionAddress = this.pickUpAddress;
        this.userService.selectedServiceMobilityDeliveryOptionAddress = this.deliveryAddress;
        this.bookingService.updateBooking().pipe(take(1)).subscribe(() => {
            this.bookingService.confirmBooking().pipe(take(1)).subscribe(result => {
                if (result) {
                    this.userService.wipNumber = result;
                    this.router.navigateByUrl('/booking-confirmation');
                } else {
                    this.errorService.show(992, false);
                }
            });
        });
    }

    setDefaultDate(workingDate) {
        if (!this.model) {
            this.model = this.calendar.getToday();
        }
        if (workingDate) {
            this.model.year = workingDate.getFullYear();
            this.model.month = workingDate.getMonth() + 1;
            this.model.day = workingDate.getDate();
            this.startDate.year = workingDate.getFullYear();
            this.startDate.month = workingDate.getMonth() + 1;
            this.startDate.day = workingDate.getDate();
        }
    }

    isDisabled = (date: NgbDateStruct, current: { month: number }) => {
        return this.getIsDisabled(date);
    }

    onDateChange(date: NgbDateStruct) {

        this.userService.selectedServiceDate = new Date(date.year, date.month - 1, date.day);
        this.getAvailableDropOffTimes(new Date(date.year, date.month - 1, date.day), undefined, true);
        this.bookingDate = new Date(date.year, date.month - 1, date.day);
    }

    setOption(mobilityType: OptionsModel) {


        this.mobilityOptions.forEach(x => x.selected = false);
        mobilityType.selected = !mobilityType.selected;
        this.mobilityRequired = !mobilityType.optionID.includes('STAN');
        this.getAvailableDropOffTimes(this.bookingDate, mobilityType.optionID, true);


    }


    getAvailableDropOffTimes(date: Date, mobilityType?: string, ignoreAdvirorSwitch?: boolean) {
        this.timeOptions = [];
        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            this.optionService.GetAvailableDropOffTimesTitan(date).pipe(take(1)).subscribe(result => {
                result.forEach(x => {
                //     console.log(x);
                //     console.log(x.toString());
                    this.timeOptions.push({
                        fullOption: x.toString(),
                        shortOption: `${x.getHours().toString().length < 2 ? '0' + x.getHours().toString() : x.getHours().toString()}:${x.getMinutes().toString().length < 2 ? '0' + x.getMinutes().toString() : x.getMinutes().toString()}`
                    });
                });
            });
            // ////////////////////
            // this.timeOptions.push({
            //     fullOption: x.toString(),
            //     shortOption: `${x.getHours().toString().length < 2 ? '0' + x.getHours().toString() : x.getHours().toString()}:${x.getMinutes().toString().length < 2 ? '0' + x.getMinutes().toString() : x.getMinutes().toString()}`
            // });
            // ///////////////////
        } else {
            this.optionService.GetAvailableDropOffTimes(date).pipe(take(1)).subscribe(timeSlots => {
                this.timeOptions = [];

                if (this.mobilityOptions.length === 0 ) {


                    timeSlots.find(x => x.optionID.includes('STAN')).selected = true;
                    ['COLL', 'STAN' ].forEach(x => {
                        timeSlots.filter(y => y.optionID.includes(x)).forEach(item => {
                            this.mobilityOptions.push(item);
                            this.pickUpAndDelivery = true;
                        });
                    });

                    if (this.isBMW && this.isSouthAfrica) {
                      this.mobilityOptions = this.mobilityOptions.filter((a) => a.optionID !== "STANM");
                      this.mobilityOptions.sort((a, b) => a.optionID > b.optionID ? -1 : 1);
                       const selectedMobilityOption = this.mobilityOptions.find((mobilityOption) => mobilityOption.optionID === "COLL")


                      if (this.userService.selectedServiceMobilityOptionAddress && this.userService.selectedServiceMobilityOptionAddress.length > 0 && this.userService.selectedServiceMobilityDeliveryOptionAddress && this.userService.selectedServiceMobilityDeliveryOptionAddress.length > 0) {


                        this.userService.selectedServiceMobilityOption = "COLL";

                        this.setOption(selectedMobilityOption)

                      }

                    } else {
                      this.mobilityOptions.sort((a, b) => a.optionID > b.optionID ? -1 : 1);
                    }
                }

                let option;
                if (mobilityType) {
                    option = timeSlots.find(x => x.optionID === mobilityType);
                    this.pickUpAndDelivery = true;
                } else {
                    option = timeSlots.find(x => x.optionID.includes('STAN'));
                }

                if (this.configService.configSettings.switchAdvisorWorkflow) {
                    let timeSlot = option.timeCode[option.timeCode.length - 1].substring(6, 11);
                    timeSlot = `${(parseInt(timeSlot.substring(0, 2)) + 1).toString()}:00`;
                    while (timeSlot !== '24:00') {
                        let minutes = timeSlot.substring(3, 5);
                        let hour = timeSlot.substring(0, 2);
                        if (parseInt(minutes) === 30) {
                            minutes = '00';
                            hour = (parseInt(hour) + 1).toString();
                        } else {
                            minutes = (parseInt(minutes) + 30).toString();
                        }
                        const previousTimeSlot = timeSlot;
                        timeSlot = `${hour}:${minutes}`;
                        option.timeCode.push(`${previousTimeSlot}-${timeSlot}`);
                    }
                }

                this.userService.selectedServiceMobilityOption = option.optionID;
                this.userService.serviceOptionsReady = true;

                option.timeCode.forEach(x => {
                    if (this.configService.configSettings.switchAdvisorWorkflow) {
                        this.serviceAdvisorService.getServiceAdvisors(undefined, x.substring(0, 5), ignoreAdvirorSwitch).pipe(take(1)).subscribe(result => {
                            if (this.userService.serviceAdvisor && result.find(advisor => advisor.advisorID === this.userService.serviceAdvisor.advisorID) !== undefined) {
                                this.timeOptions.push({ shortOption: x.substring(0, 5), fullOption: x });
                                this.timeOptions.sort((a, b) => a.shortOption < b.shortOption ? -1 : 1);
                            }
                        });
                    } else {
                        this.timeOptions.push({ shortOption: x.substring(0, 5), fullOption: x });
                    }
                });
            });
        }
    }

    getIsDisabled(dateToCheck: NgbDateStruct) {
        const date: Date = new Date(dateToCheck.year, dateToCheck.month - 1, dateToCheck.day);
        if (!this.serviceDates) return true;
        const index = this.serviceDates.workingDates.findIndex(d => d.toDateString() === date.toDateString());
        return index === -1;
    }

    selectTimeSlot(slot: string) {
        this.selectedSlot = slot;
    }

    selectPickUpTimeSlot(slot: string) {

    }

    isCollection(option: OptionsModel) {

        return option.optionID.includes('COLL');
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    submit() {
        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            const dropOffTime = new Date(this.selectedSlot);
            const dropOffMonth = (this.bookingDate.getMonth() + 1).toString().length === 1 ? `0${(this.bookingDate.getMonth() + 1).toString()}` : (this.bookingDate.getMonth() + 1).toString();
            const dropOffDay = this.bookingDate.getDate().toString().length === 1 ? `0${this.bookingDate.getDate().toString()}` : this.bookingDate.getDate().toString();
            const dropOffHour = dropOffTime.getHours().toString().length === 1 ? `0${dropOffTime.getHours().toString()}` : dropOffTime.getHours().toString();
            const dropOffMinutes = dropOffTime.getMinutes().toString().length === 1 ? `0${dropOffTime.getMinutes().toString()}` : dropOffTime.getMinutes().toString();
            const dropOffTimeString = `${dropOffTime.getFullYear()}-${dropOffMonth}-${dropOffDay}T${dropOffHour}:${dropOffMinutes}:00`;

            const model: TimeSlotReservation = {
                BookingDate: dropOffTimeString,
                DropOffTime: dropOffTimeString,
                LocationId: this.userService.selectedLocation.Id,
                PickUpTime: dropOffTimeString,
            }
            if (this.userService.reservationToken && !this.userService.bookingId)
                model.ReservationToken = this.userService.reservationToken;
            this.bookingService.reserveTimeSlot(model).pipe(take(1)).subscribe(result => {
                // TODO - fix add vehicle
                this.userService.reservationToken = result;
                this.doSelectTimeSlot();
            });
        } else {
            this.doSelectTimeSlot();
        }
    }

    doSelectTimeSlot() {
        this.userService.selectedServiceDate = new Date(this.model.year, this.model.month - 1, this.model.day);
        this.userService.selectedServiceSlot = this.selectedSlot;
        this.userService.selectedServiceMobilityOptionAddress = this.pickUpAddress;
        this.userService.selectedServiceMobilityDeliveryOptionAddress = this.deliveryAddress;
        this.servicesSelected.emit('steps-date');
    }

    previous() {
        this.previousState.emit('steps-date');
    }

    validatePickUpAddressTextArea(event) {
      let specialCharactersNotAllowed = ["<", ">", "?", "/", "%","\$", "\\", ":", "'","{","}", "+", "=", "_", "\"", "!", "@", "^", "*", "(", ")" ];
      let address = event.target.value.split('')

      // debugger
     const arrayOfInvalidCharacter = address.filter(character => specialCharactersNotAllowed.includes(character));

      if (arrayOfInvalidCharacter.length > 0) {
        this.pickUpAddressTextAreaIsInvalid = true
      } else {
        this.pickUpAddressTextAreaIsInvalid = false
      }

    }

    validateDeliveryAddressTextArea(event) {
      let specialCharactersNotAllowed = ["<", ">", "?", "/", "%","\$", "\\", ":", "'","{","}", "+", "=", "_", "\"", "!", "@", "^", "*", "(", ")" ];
      let address = event.target.value.split('')


     const arrayOfInvalidCharacter = address.filter(character => specialCharactersNotAllowed.includes(character));

      if (arrayOfInvalidCharacter.length > 0) {
        this.deliveryAddressTextAreaIsInvalid = true
      } else {
        this.deliveryAddressTextAreaIsInvalid = false
      }

    }


}

class TimeSlot {
    shortOption: string;
    fullOption: string;
}
