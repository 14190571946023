export const environment = {
  production: false,
  titanUrl: 'https://api2.titandms.co.th/ServiceBookingApi/',
  serviceOnlineService: '',
  // serviceOnlineService: 'https://nodeexpresssolapi.azurewebsites.net',
  // solServerUrl: "https://serviceonlinetoken-test.azurewebsites.net",
   solServerUrl: "https://serviceonlinetoken.azurewebsites.net",
  // solServerUrl: "http://localhost:4000",

  //  solAPIUrl: "http://localhost:4000",
   solAPIUrl: "https://serviceonlineserver.azurewebsites.net",     // Prod
  // solAPIUrl: "https://nodeexpresssolapi.azurewebsites.net",        // Test

  // solAPIUrl: "https://nodeexpresssolapi.azurewebsites.net",
      solZAAPIUrl: "http://localhost:4100",
  // solZAAPIUrl: "https://solzaapi.azurewebsites.net/",
  // solAPIUrl: "https://nodeexpresssolapi.azurewebsites.net",

  // adminUrs: '',
  // adminPwd: '',


  // localCommunity: 'service.bmw.co.jp',
  // localCommunity: 'testservice.bmw.co.jp',
  // localCommunity: 'service.mini.jp',
  // localCommunity: 'testservice.mini.jp',
  
  // localCommunity: 'service.bmw.com.sg',
  // localCommunity: 'service.mini.com.sg',

  // localCommunity: 'service.bmw-iraq.com',
  // localCommunity: 'service.bmw-lebanon.com',
  // localCommunity: 'testservice.bmw-lebanon.com',
  // localCommunity: 'service.bmw.com.mt',
  // localCommunity: 'testservice.bmw.com.mt',
  // localCommunity: 'service.bmw-pakistan.com',
  // localCommunity: 'service.mini-kuwait.com',
  // localCommunity: 'service.bmw.com.my',
  // localCommunity: 'testservice.bmw.com.my',
  // localCommunity: 'service.mini.my',
  // localCommunity: 'testservice.mini.my',
  // localCommunity: 'service.bmw.co.za',
  // localCommunity: 'testservice.bmw.co.za',
  // localCommunity: 'testservice.mini.co.za',
  // localCommunity: 'testservice.munichauto.com.sg',
  // localCommunity: 'testservice.bmw.com.sg',
  // localCommunity: 'testservice.mini.com.sg',
  // localCommunity: 'service.bmw-abudhabi.com',
  // localCommunity: 'testservice.mini.co.th',
  // localCommunity: 'testservice.bmw-saudiarabia.com',
  // localCommunity: 'testservice.mini-saudiarabia.com',
  // localCommunity: 'service.bmw-saudiarabia.com',
  // localCommunity: 'service.bmw-saudiarabia.com',
  // localCommunity: 'service.mini-saudiarabia.com',

  // localCommunity: 'testservice.mini-qatar.com',
  // localCommunity: 'testservice.bmw-qatar.com',
  // localCommunity: 'service.mini-qatar.com',
  // localCommunity: 'service.bmw-qatar.com',

  // localCommunity: 'testservice.mini-dubai.com',
  // localCommunity: 'testservice.bmw-dubai.com',
  // localCommunity: 'service.bmw.mu',
  // localCommunity: 'service.bmw.ps',
  // localCommunity: 'service.bmw-dubai.com',
  // localCommunity: 'service.mini-dubai.com',

  // localCommunity: 'testservice.mini.co.nz',
  // localCommunity: 'testservice.bmw.co.nz',
  // localCommunity: 'service.bmw.co.nz',
  // localCommunity: 'service.bmw.co.nz',
  // localCommunity: 'service.mini.co.nz',

  // localCommunity: 'service.bmw.co.th',
  // localCommunity: 'testservice.bmw.co.th',

  localCommunity: 'testservice.bmw-abudhabi.com',
  // localCommunity: 'testservice.mini-abudhabi.com',
  // localCommunity: 'service.mini-abudhabi.com',
  // localCommunity: 'service.bmw-abudhabi.com',

  // v
  // localCommunity: 'testservice.mini-abudhabi.com',
  // localCommunity: 'service.bmw-kuwait.com',

  // localCommunity: 'service.bmw-abudhabi.com',

  // localCommunity: 'service.bmw-oman.com',
  // localCommunity: 'testservice.bmw-oman.com',
  // localCommunity: 'service.mini-oman.com',

  // localCommunity: 'service.mini-bahrain.com',
  // localCommunity: 'service.bmw-bahrain.com', 

  // localCommunity: 'service.bmw-jordan.com',
  // localCommunity: 'testservice.bmw-jordan.com',
  // localCommunity: 'testservice.bmw.co.id',
  // localCommunity: 'testservice.mini.co.id',
  // localCommunity: 'service.bmw.co.id',
  // localCommunity: 'service.mini.co.id',
  // localCommunity: 'service.bmw.com.au',
  // localCommunity: 'testservice.bmw.com.au',
  // localCommunity: 'service.mini.com.au',
  // localCommunity: 'testservice.mini.com.au',

  // localCommunity: 'service.mini.com.sg',
  // localCommunity: 'service.bmw.com.sg',

  // localCommunity: 'service.bmw-palestine.com',

  cosyImageApi: 'https://solcosy.azurewebsites.net/servlet/CosyUrlJson',
  crmCosyImageApi: 'https://digitalshow-i.bmwgroup.com/ds/Rockar/v1/cosyimages',
  // cosyImageApi: ' https://int.cosy.bmw.cloud',
  brandLookUpCosy: {
    B: 'BM',
    E: 'BI',
    I: 'BI',
    M: 'MI',
    1: 'BM',
    17: 'BM'
  },
  LevelOneApiUrl: 'http://solapi.azurewebsites.net/api/v1/',
  MailerUrl: 'https://solmail.azurewebsites.net',
  // MailerUrl: 'http://localhost:7000/'
};
