import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { UserService } from '../user-account/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginErrorService {
  errorCode: number;
  errorDescription: string;
  errorHeader: string;
  isInErrorStateNew = new Subject<boolean>();

  constructor(
    private readonly translate: TranslateService,
    private userService: UserService
  ) {}

  show(errorCode: number) {
    this.errorCode = errorCode;
    this.errorHeader = this.errors.find((error) => error.value === errorCode).header;
    this.errorDescription = this.errors.find((error) => error.value === errorCode).description;
    this.isInErrorStateNew.next(true);
  }

  hide() {
    this.errorCode = 0;
    this.errorDescription = '';
    this.isInErrorStateNew.next(false);
  }

  errors = [
    {
        header: 'login.errorHeader.24',
        value: 24,
        description: 'login.error.24'
    },
    {
        header: 'login.errorHeader.25',
        value: 25,
        description: 'login.error.25'
    }
  ]
}
