import { MouseEvent } from '@agm/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProgressModel } from './components/shared/progress-indicator/progress-item.model';
import { LoaderService } from './services/loader/loader.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { TitleService } from './services/title/title.service';
import { ConfigService } from './services/config/config.service';
import { filter, take } from 'rxjs/operators';
import { ErrorService } from './services/error/error.service';
import { LoginErrorService } from './services/error/loginError.service';
import { RegisterErrorService } from './services/error/registerError.service';
import { ResetPasswordErrorService } from './services/error/resetPasswordError.service';
import { BookingService } from './services/booking/booking.service';
import { UserService } from './services/user-account/user/user.service';
import { LoginService } from './services/user-account/login/login.service';



import {
  NgcCookieConsentService,
  NgcNoCookieLawEvent,
  NgcInitializingEvent,
  NgcStatusChangeEvent,
  NgcInitializationErrorEvent,
} from "ngx-cookieconsent";

import { Subscription } from "rxjs";

// declare let gtag: Function;


@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
  zoom: number = 8;
  lat: number = 51.673858;
  lng: number = 7.815982;

  progressItems: ProgressModel[];
  showProgress: boolean;
  isLoading: boolean;
  isLoadingManual: boolean;
  showLoader: boolean;
  isInErrorState: boolean;
  isInErrorStateNew: boolean;
  errorMessage: string;
  errorHeader: string;
  showDefaultHeading: boolean;
  brand: string;
  isRtl: boolean;
  isLevelOneBooking: boolean;
  showSubMenu: boolean = true;
  confirmLogout: boolean;
  urlPath;
  isNotIframed = true;
  isNewZealand = false;
  isSingapore: boolean;
 
  // private popupOpenSubscription: Subscription;
  // private popupCloseSubscription: Subscription;
  // private initializeSubscription: Subscription;
  // private statusChangeSubscription: Subscription;
  // private revokeChoiceSubscription: Subscription;
  // private noCookieLawSubscription: Subscription;

  // private popupOpenSubscription!: Subscription;
  // private popupCloseSubscription!: Subscription;
  // private initializingSubscription!: Subscription;
  // private initializedSubscription!: Subscription;
  // private initializationErrorSubscription!: Subscription;
  // private statusChangeSubscription!: Subscription;
  // private revokeChoiceSubscription!: Subscription;
  // private noCookieLawSubscription!: Subscription;

  constructor(
    private translate: TranslateService,
    private readonly loaderService: LoaderService,
    private readonly router: Router,
    private readonly titleService: TitleService,
    private readonly configService: ConfigService,
    private readonly errorService: ErrorService,
    private readonly loginErrorService: LoginErrorService,
    private readonly registerErrorService: RegisterErrorService,
    private readonly resetPasswordErrorService: ResetPasswordErrorService,
    private readonly bookingService: BookingService,
    private readonly userService: UserService,
    private loginService: LoginService,

    // private cookieService: NgcCookieConsentService
  ) {
    // Google Analytics
    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      //  gtag('event', 'page_view', {
      //     page_path: event.urlAfterRedirects
      //  })
    })
 /** END : Code to Track Page View  using gtag.js */
  }

  ngOnInit() {
    this.bookingService.isLevelOneBooking$.subscribe((result) => {
      this.isLevelOneBooking = result;
      this.showSubMenu = !this.isLevelOneBooking;
    });

    this.isNotIframed = !this.configService.configSettings.isCRM;
    this.isSingapore = this.configService.configSettings.country === "Singapore"
    this.isNewZealand =
      this.configService.configSettings.language.indexOf('nz') > -1;
    this.loaderService.isLoading.subscribe((result) => {
      this.showLoader = result || this.isLoadingManual;
      this.isLoading = result;
    });

    this.loaderService.isLoadingManual.subscribe((result) => {
      this.showLoader = result || this.isLoading;
      this.isLoadingManual = result;
    });

    this.errorService.isInErrorStateNew.subscribe((result) => {
      this.isInErrorStateNew = result;
      this.errorMessage = result ? `${this.errorService.errorDescription}` : '';
      this.errorHeader = result ? `${this.errorService.errorHeader}` : '';
      this.showDefaultHeading = this.errorService.showDefaultHeading;
      if (result) this.router.navigate([{ outlets: { popup: null } }]);
    });

    this.loginErrorService.isInErrorStateNew.subscribe((result) => {
      this.isInErrorStateNew = result;
      this.errorMessage = result ? `${this.loginErrorService.errorDescription}` : '';
      this.errorHeader = result ? `${this.loginErrorService.errorHeader}` : '';
      if (result) this.router.navigate([{ outlets: { popup: null } }]);
    });

    this.registerErrorService.isInErrorStateNew.subscribe((result) => {
      this.isInErrorStateNew = result;
      this.errorMessage = result ? `${this.registerErrorService.errorDescription}` : '';
      this.errorHeader = result ? `${this.registerErrorService.errorHeader}` : '';
      if (result) this.router.navigate([{ outlets: { popup: null } }]);
    });

    this.resetPasswordErrorService.isInErrorStateNew.subscribe((result) => {
      this.isInErrorStateNew = result;
      this.errorMessage = result ? `${this.resetPasswordErrorService.errorDescription}` : '';
      this.errorHeader = result ? `${this.resetPasswordErrorService.errorHeader}` : '';
      if (result) this.router.navigate([{ outlets: { popup: null } }]);
    });

    this.errorService.isInErrorState.subscribe((result) => {
      this.isInErrorState = result;
      this.errorMessage = result ? `${this.errorService.errorDescription}` : '';
      this.showDefaultHeading = this.errorService.showDefaultHeading;
      if (result) this.router.navigate([{ outlets: { popup: null } }]);
    });

    const languages: string[] = [];
    this.configService.configSettings.languages.forEach((lang) => {
      languages.push(lang.language);
    });
    this.translate.addLangs(languages);
    this.translate.setDefaultLang(this.configService.configSettings.language);
    this.translate.use(this.configService.configSettings.language);
    this.isRtl = this.configService.configSettings.language.includes('ar_');

    this.translate.onLangChange.subscribe((result) => {
      this.isRtl = result.lang.includes('ar_');
    });

    this.titleService.getTitleList();

    this.progressItems = [
      {
        label: 'progress-login',
        taskName: '',
        taskNumber: 1,
        isComplete: false,
      },
      {
        label: 'progress-dealer',
        taskName: '',
        taskNumber: 2,
        isComplete: false,
      },
      {
        label: 'progress-service',
        taskName: '',
        taskNumber: 3,
        isComplete: false,
      },
      {
        label: 'progress-complete',
        taskName: '',
        taskNumber: 4,
        isComplete: false,
      },
    ];

    if (this.configService.configSettings.isCRM) {
      this.progressItems = [
        {
          label: 'progress-dealer',
          taskName: '',
          taskNumber: 1,
          isComplete: false,
        },
        {
          label: 'progress-service',
          taskName: '',
          taskNumber: 2,
          isComplete: false,
        },
        {
          label: 'progress-complete',
          taskName: '',
          taskNumber: 3,
          isComplete: false,
        },
      ];
    }

    this.translate.onLangChange.subscribe(() => {
      this.progressItems.forEach((x) =>
        this.translate
          .get(x.label)
          .pipe(take(1))
          .subscribe((result) => (x.taskName = result))
      );
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.shouldShowProgress(event.url);
        this.setProgressState(event.url);
      }
    });
    this.brand = this.configService.brand;



    // End

  }

  clickedMarker(label: string, index: number) {}

  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
    });
  }

  markerDragEnd(m: marker, $event: MouseEvent) {}

  markers: marker[] = [
    {
      lat: 51.673858,
      lng: 7.815982,
      label: 'A',
      draggable: true,
    },
    {
      lat: 51.373858,
      lng: 7.215982,
      label: 'B',
      draggable: false,
    },
    {
      lat: 51.723858,
      lng: 7.895982,
      label: 'C',
      draggable: true,
    },
  ];

  shouldShowProgress(path: string) {
    this.showProgress =
      path.indexOf('my-bookings') === -1 && path.indexOf('profile') === -1;
  }

  setProgressState(path: string): void {
    this.progressItems.forEach(
      (progressItem) => (progressItem.isComplete = false)
    );

    switch (path) {
      case '/login':
        break;
      case '/user-account/login':
        break;
      case '/select-dealer':
        this.progressItems[0].isComplete = true;
        break;
      case '/select-service':
        this.progressItems[0].isComplete = true;
        this.progressItems[1].isComplete = true;
        break;
      case '/booking-confirmation':
        this.progressItems[0].isComplete = true;
        this.progressItems[1].isComplete = true;
        this.progressItems[2].isComplete = true;
        break;
    }
  }

  closeModel() {
    if (this.errorService.errorCode === 991) {

      this.router.navigateByUrl('/select-dealer');
    }
    this.errorService.hide();
  }

  logOut() {
    this.confirmLogout = true;
  }

  cancelLogOut() {
    this.confirmLogout = false;
  }

  doLogOut() {
    this.bookingService.clearBookingData();
    if (
      this.userService.selectedDealer &&
      this.userService.selectedDealer.TitanDealer
    ) {
      this.router.navigateByUrl('/user-account/login');
    } else {
      this.loginService.logOut().subscribe(
        (result) => {
          if (result) {
            this.confirmLogout = false;
            this.router.navigateByUrl('/user-account/login');
          }
        },
        () => {
          this.router.navigateByUrl('/user-account/login');
        }
      );
    }
  }

  // startCookieSubscription() {
  //    // Cookie Subscriptions
  //     // subscribe to cookieconsent observables to react to main events
  //     this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(
  //       () => {
  //         // you can use this.cookieService.getConfig() to do stuff...
  //       });

  //     this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(
  //       () => {
  //         // you can use this.cookieService.getConfig() to do stuff...
  //       });

  //     this.initializingSubscription = this.cookieService.initializing$.subscribe(
  //       (event: NgcInitializingEvent) => {
  //         // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
  //         console.log(`initializing: ${JSON.stringify(event)}`);
  //       });

  //     this.initializedSubscription = this.cookieService.initialized$.subscribe(
  //       () => {
  //         // the cookieconsent has been successfully initialized.
  //         // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
  //         console.log(`initialized: ${JSON.stringify(event)}`);
  //       });

  //     this.initializationErrorSubscription = this.cookieService.initializationError$.subscribe(
  //       (event: NgcInitializationErrorEvent) => {
  //         // the cookieconsent has failed to initialize...
  //         console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
  //       });

  //     this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
  //       (event: NgcStatusChangeEvent) => {
  //         // you can use this.cookieService.getConfig() to do stuff...
  //       });

  //     this.revokeChoiceSubscription = this.cookieService.revokeChoice$.subscribe(
  //       () => {
  //         // you can use this.cookieService.getConfig() to do stuff...
  //       });

  //       this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe(
  //       (event: NgcNoCookieLawEvent) => {
  //         // you can use this.cookieService.getConfig() to do stuff...
  //       });
  // }

  ngOnDestroy() {


     // unsubscribe to cookieconsent observables to prevent memory leaks
    //  this.popupOpenSubscription.unsubscribe();
    //  this.popupCloseSubscription.unsubscribe();
    //  this.initializingSubscription.unsubscribe();
    //  this.initializedSubscription.unsubscribe();
    //  this.initializationErrorSubscription.unsubscribe();
    //  this.statusChangeSubscription.unsubscribe();
    //  this.revokeChoiceSubscription.unsubscribe();
    //  this.noCookieLawSubscription.unsubscribe();
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
