import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { take } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config/config.service';
import { ResetPasswordErrorService } from 'src/app/services/error/resetPasswordError.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.less']
})

export class ResetPasswordComponent implements OnInit {
    emailAddress: string;
    token: string;
    showSuccess: boolean;
    isSingapore: boolean;
    errorCode: number;
    errorHeader: string;
    errorMessage: string;

    constructor(
        private readonly router: Router,
        private readonly userService: UserService,
        private readonly route: ActivatedRoute,
        private configService: ConfigService,
        private resetPasswordErrorService: ResetPasswordErrorService
    ) {

    }

    ngOnInit() {
        this.isSingapore = this.configService.configSettings.language.indexOf('sg') > -1;
        const paramMap = this.route.snapshot.queryParams;
        if (paramMap.token)
            this.token = paramMap.token;

        this.resetPasswordErrorService.isInErrorStateNew.subscribe((result) => {
            this.errorMessage = result ? `${this.resetPasswordErrorService.errorDescription}` : '';
            this.errorHeader = result ? `${this.resetPasswordErrorService.errorHeader}` : '';
            if (result) this.router.navigate([{ outlets: { popup: null } }]);
          });
    }

    resetPasswordDone() {
        this.showSuccess = true;
        setTimeout(() => {
            this.router.navigateByUrl('/');
        }, 6000);
    }

    resetPassword() {
        if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
            this.userService.resetPasswordTitan(this.emailAddress).pipe(take(1)).subscribe((response) => {
                if (response.errorCode !== 0) {
                    this.handleError(response.errorCode);
                } else {
                    this.resetPasswordDone();
                }
            });
        } else {
            this.userService.resetPassword(this.emailAddress).pipe(take(1)).subscribe(response => {
                if (response.ErrorCode !== 0) {
                    this.handleError(response.ErrorCode);
                } else {
                    this.resetPasswordDone();
                }
            });
        }
    }

    navigateToLogin() {
        this.router.navigateByUrl('/');
    }

    handleError(error: number) {
        this.errorCode = error;
        switch (this.errorCode) {
            case 24:
                this.resetPasswordErrorService.show(24);
        }
    }
}
